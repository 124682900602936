// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, addDoc, collection } from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDRJLodIvckAGnpmfGfn86I5KlMIzBZfoM',
  authDomain: 'dpo-peinture.firebaseapp.com',
  projectId: 'dpo-peinture',
  storageBucket: 'dpo-peinture.appspot.com',
  messagingSenderId: '628989191270',
  appId: '1:628989191270:web:a79b259b4a4ae75f9379a7',
  measurementId: 'G-BW07GCFE04',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage()

// Create a new cart
const createContact = async function createContact(contact) {
  const res = await addDoc(collection(db, 'contacts'), contact)
  return res.id
}

const uploadFileToStorage = async function uploadFileToStorage(file, name, email) {
  const storageRef = ref(storage, `contacts/${email}/${name}`)
  const res = await uploadBytes(storageRef, file)
  return res
}

export { createContact, uploadFileToStorage }
