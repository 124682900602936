<template>
  <div id="app">
    <div class="main-wrapper">
      <transition name="page" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
// import NavBar from '@/components/NavBar'
import { isNil } from 'lodash'
import initInViewAnimations from '@/misc/animations-layer'

export default {
  components: {},
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          // if ($route.meta.lang === 'en') {
          //   this.$i18n.locale = 'en'
          // } else {
          //   this.$i18n.locale = 'fr'
          // }
        }

        if (window.location.href.includes('dpopeinture')) {
          // Redirect to https://dpocuisine.com/
          window.location.href = 'https://dpocuisine.com/'
        }

        setTimeout(() => {
          initInViewAnimations()
        }, 1)
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/vendors.scss';
@import '@/theme/base.scss';
@import '@/theme/common.scss';
@import '@/theme/animations-layer.scss';
@import '@/theme/typo.scss';
@import '@/theme/site.scss';

.page-enter-active,
.page-leave-active {
  transition-duration: 1.471s;
  transition-property: opacity, transform;
  // transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);

  [hero-transition-group] {
    transition: opacity 1s cubic-bezier(0.35, 0, 0.22, 1) 0.45s, transform 2.1s cubic-bezier(0.35, 0, 0.22, 1) 0s;
  }
}

.page-enter,
.page-leave-active {
  [hero-transition-group] {
    transform: translate3d(0, 45px, 0);
    opacity: 0;
  }
}
</style>
