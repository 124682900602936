<template>
  <div class="page-wrapper">
    <!-- Main Nav -->
    <nav id="main-nav" class="main-nav site-max-width large">
      <div class="inner main-nav-container" main-nav-content>
        <div class="logo">
          <Logo />
        </div>
        <div class="links">
          <a href="tel:514 686-7117" class="tel" title="Appeler 514 686-7117">514 686-7117</a>
          <a href="tel:514 686-7117" class="tel-mobile" title="Appeler 514 686-7117">
            <svg height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#202935"/>
              <path d="M15.3387 8.66667C14.2356 8.66667 13.3334 9.5733 13.3334 10.6818V29.3182C13.3334 30.4267 14.2356 31.3333 15.3387 31.3333H24.6614C25.7645 31.3333 26.6667 30.4267 26.6667 29.3182V10.6818C26.6667 9.5733 25.7645 8.66667 24.6614 8.66667L15.3387 8.66667ZM18.8833 10.6074H21.1186C21.2658 10.6074 21.3847 10.7765 21.3847 10.9855C21.3847 11.1944 21.2658 11.3636 21.1186 11.3636H18.8833C18.7362 11.3636 18.6172 11.1944 18.6172 10.9855C18.6172 10.7765 18.7362 10.6074 18.8833 10.6074ZM14.4356 13.3008H25.5663V26.6983H14.4356V13.3008ZM20.0005 27.7679C20.686 27.7679 21.2419 28.3265 21.2419 29.0154C21.2419 29.7042 20.686 30.2629 20.0005 30.2629C19.315 30.2629 18.7591 29.7042 18.7591 29.0154C18.7591 28.3265 19.315 27.7679 20.0005 27.7679Z" fill="#38AFFB"/>
            </svg>
          </a>
          <a href="#" class="site-btn" title="Demandez une soumission" @click.prevent="togglePanel(true)">Demandez une soumission</a>
        </div>
      </div>

      <div class="bg" main-nav-bg></div>
    </nav>

    <!-- Hero -->
    <header id="hero" class="hero site-max-width">
      <div class="inner hero-container">
        <div class="content" smooth-down>
          <h1 class="hero-title" data-inview="fadeInUp" data-delay="100">Votre projet, notre expertise.</h1>
          <h2 class="hero-subtitle" data-inview="fadeInUp" data-delay="200">Merci de votre confiance.</h2>
          <p class="hero-text" data-inview="fadeInUp" data-delay="300">
            Votre demande a été envoyée avec succès. Nous vous contacterons dans les plus brefs délais.
          </p>
          <div class="ctas" data-inview="fadeInUp" data-delay="600">
            <!-- <a href="#" class="site-btn" title="Demandez une soumission" @click.prevent="togglePanel(true)">Demandez une soumission</a> -->
            <a class="site-btn outlined" title="Retour" href="/">Retour</a>
          </div>
        </div>
      </div>
    </header>

    <!-- Footer -->
    <footer id="footer" class="footer site-max-width">
      <br/><br/>
      <div class="inner footer-container">
        
        <div class="left" data-inview="fadeInUp" data-delay="100">
          <p>&copy; DPO Peinture - Tous droits réservés</p>
        </div>
        <div class="right" data-inview="fadeInUp" data-delay="400">
          Par
          <a href="https://jnv.dev/" target="_blank" rel="noopener noreferrer">
            <svg height="20" viewBox="0 0 52 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M41.2573 24.2757H41.1255L30.6465 0H34.0077L41.1255 17.6047H41.2573L48.6388 0H52L41.2573 24.2757Z" fill="#51504D" />
              <path
                d="M32.294 19.6874L35.2598 24.3407H31.9645L30.4293 22.2256C28.8256 23.6357 27.0022 24.3407 24.9591 24.3407C22.5645 24.3407 20.5324 23.5489 18.8628 21.9652C17.1932 20.3816 16.3584 18.4291 16.3584 16.1078C16.3584 14.7411 16.7209 13.4503 17.4458 12.2354C18.1928 10.9989 19.1923 10.0009 20.4445 9.24167C19.3022 7.78816 18.731 6.49738 18.731 5.36928C18.731 3.89409 19.2912 2.63583 20.4116 1.59451C21.532 0.531503 22.8721 0 24.4319 0C25.6841 0 26.7715 0.357952 27.6942 1.07386C28.6692 1.78976 29.7759 3.74222 29.8225 5.3042H27.1011C26.5272 3.41681 25.5962 2.86362 24.4319 2.86362C23.663 2.86362 23.0259 3.0914 22.5206 3.54698C22.0373 4.00255 21.7956 4.61 21.7956 5.36928C21.7956 6.10689 22.0483 6.80109 22.5535 7.45191L30.3634 17.5396L32.1292 14.0577H34.8449L33.6121 16.9214L32.294 19.6874ZM19.1264 16.1078C19.1264 17.6698 19.6866 18.9823 20.807 20.0453C21.9274 21.0866 23.3115 21.6073 24.9591 21.6073C26.5189 21.6073 27.7821 21.0866 28.7487 20.0453L22.1252 11.4219C21.2025 11.9209 20.4665 12.5934 19.9173 13.4395C19.3901 14.2855 19.1264 15.175 19.1264 16.1078Z"
                fill="#51504D"
              />
              <path
                d="M7.34852 24.4709C5.56906 24.4709 4.03124 23.9936 2.7351 23.0391C0.790875 21.6073 0 19.6874 0 17.8976H2.96577C3.46006 20.3382 5.10771 21.6073 7.34852 21.6073C8.57875 21.6073 9.56734 21.1842 10.3143 20.3382C11.0832 19.4704 11.4676 18.3532 11.4676 16.9864V0H14.4993V16.7261C14.4993 19.1342 13.8512 21.0324 12.5551 22.4208C11.2809 23.7875 9.5454 24.4709 7.34852 24.4709Z"
                fill="#51504D"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>

    <!-- SideForm -->
    <SideForm :togglePanel="togglePanel" :panelIsOpen="panelIsOpen" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Logo from '@/components/svgs/Logo'
import SideForm from '@/components/SideForm'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Home',

  components: {
    Logo,
    SideForm,
  },

  head() {
    return {
      title: {
        inner: `Entreprise spécialisée dans le resurfaçage d’armoires de cuisine de tout genre`,
      },
      link: [
        {
          rel: 'canonical',
          href: 'https://dpopeinture.com/'
        }
      ],
      meta: [
        {
          name: 'description',
          content: `l’entreprise DPO Peinture Inc. est depuis quelques années, un chef de file dans le domaine du resurfaçage de cuisine et meubles en tous genres. Notre équipe fiable et expérimentée offre le travail de qualité que vous méritez.`,
          id: 'desc',
        },
        {
          name: 'application-name',
          content: `Entreprise spécialisée dans le resurfaçage d’armoires de cuisine de tout genre`,
        },
        {
          name: 'twitter:title',
          content: `l’entreprise DPO Peinture Inc. est depuis quelques années, un chef de file dans le domaine du resurfaçage de cuisine et meubles en tous genres. Notre équipe fiable et expérimentée offre le travail de qualité que vous méritez.`,
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `l’entreprise DPO Peinture Inc. est depuis quelques années, un chef de file dans le domaine du resurfaçage de cuisine et meubles en tous genres. Notre équipe fiable et expérimentée offre le travail de qualité que vous méritez.`,
        },
        // Facebook / Open Graph
        {
          property: 'og:title',
          content: `Entreprise spécialisée dans le resurfaçage d’armoires de cuisine de tout genre`,
        },
        {
          property: 'og:site_name',
          content: `Entreprise spécialisée dans le resurfaçage d’armoires de cuisine de tout genre`,
        },
        {
          property: 'og:description',
          content: `l’entreprise DPO Peinture Inc. est depuis quelques années, un chef de file dans le domaine du resurfaçage de cuisine et meubles en tous genres. Notre équipe fiable et expérimentée offre le travail de qualité que vous méritez.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: '/img/dpo-peinture-og.jpg',
        },
        {
          name: 'twitter:image',
          content: '/img/dpo-peinture-og.jpg',
        },
      ],
    }
  },

  data() {
    return {
      mainCarouselFlk: null,
      carouselServicesFlk: null,
      panelIsOpen: false,
      heroSlide: 1,
      heroSlidesActivated: false,
    }
  },

  watch: {
    $route: {
      handler($route) {
        if ($route) {
          setTimeout(() => {
            // On Window scroll
            window.addEventListener('scroll', () => {
              const scrollTop = window.pageYOffset || document.documentElement.scrollTop
              // On scroll, if scrollTop > 100, show the navbar
              if (scrollTop > 100) {
                document.querySelector('.main-nav').classList.add('fixed')
              } else {
                document.querySelector('.main-nav').classList.remove('fixed')
              }
            })

            this.initGsap()
          }, 155)

          setTimeout(() => {
            setInterval(() => {
              this.heroSlide += 1
              if (this.heroSlide > 4) {
                this.heroSlide = 1
              }
            }, 2795)
            this.heroSlidesActivated = true
          }, 4755)
        }
      },
      immediate: true,
    },
  },

  methods: {
    togglePanel(value) {
      this.panelIsOpen = value
    },

    initGsap() {
      gsap.to('[main-nav-bg]', {
        width: '100%',
        delay: 0.2,
        duration: 2,
        opacity: '1',
        ease: 'power3.out',
      })

      gsap.to('[main-nav-content]', {
        duration: 1,
        y: '0',
        delay: 1.5,
        opacity: '1',
        ease: 'power3.out',
      })

      gsap.utils.toArray('.particule').forEach((particule) => {
        gsap.to(particule, {
          scrollTrigger: {
            trigger: particule,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1.475,
          },
          y: '-28%',
          ease: 'none',
        })
      })

      gsap.utils.toArray('[smooth-down]').forEach((sd) => {
        gsap.to(sd, {
          scrollTrigger: {
            trigger: sd,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1.475,
          },
          y: '12%',
          ease: 'none',
        })
      })

      gsap.utils.toArray('[smooth-down-delay]').forEach((sd) => {
        gsap.to(sd, {
          scrollTrigger: {
            trigger: sd,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1.475,
          },
          y: '18%',
          ease: 'none',
        })
      })
    },
  },

  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
