<template>
  <div class="side-panel" :class="{ showpanel: panelIsOpen }">
    <div class="panel">
      <p class="font-black">{{ 'Demandez une soumission' }}</p>
      <br />
      <form action="/success" name="soumissions" method="post" enctype="multipart/form-data" @submit="sendForm">
        <input type="hidden" name="form-name" value="soumissions" v-model="form.formName" />

        <input type="text" placeholder="* Prénom et nom" name="nom" required v-model="form.nom" />
        <input type="email" placeholder="* Courriel" name="courriel" required v-model="form.courriel" />

        <input type="text" placeholder="Téléphone" name="telephone" v-model="form.telephone" />
        <input type="text" placeholder="Adresse" name="adresse" v-model="form.adresse" />

        <div class="file-input-wrap">
          <input type="file" multiple placeholder="Photos de votre cuisine" name="photo" @change="getNbOfFiles" accept="image/png, image/gif, image/jpeg" />
          <span class="input-ph">Photos de votre cuisine <span v-if="nbOfFiles">({{ nbOfFiles }})</span></span>
          <svg width="23" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 12.5C17.5 13.8812 16.3812 15 15 15C13.6188 15 12.5 13.8812 12.5 12.5C12.5 11.1188 13.6188 10 15 10C16.3812 10 17.5 11.1188 17.5 12.5Z" fill="#183761"/>
            <path d="M27.5 3.3328H20.8328L19.1656 0H10.8328L9.1656 3.3328H2.5C1.0828 3.3328 0 4.4156 0 5.8328V19.1656C0 20.4984 1.0828 21.6656 2.5 21.6656H27.5C28.9172 21.6656 30 20.4984 30 19.1656V5.8328C30 4.41716 28.9172 3.3328 27.5 3.3328ZM15 18.3328C11.75 18.3328 9.1672 15.75 9.1672 12.5C9.1672 9.25 11.75 6.6672 15 6.6672C18.25 6.6672 20.8328 9.25 20.8328 12.5C20.8328 15.75 18.25 18.3328 15 18.3328Z" fill="#183761"/>
          </svg>
        </div>

        <input type="hidden" name="customSource" value="" v-model="form.customSource" />

        <!-- <textarea placeholder="Message" name="message"></textarea> -->

        <div class="ctas">
          <button class="site-btn" type="submit" :class="{ 'is-loading': isSending }" >
            <span>Demandez une soumission</span>
            <svg
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#fff"
          >
              <g transform="translate(1 1)" stroke-width="1" fill="none" fill-rule="evenodd">
                  <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="1s"
                          repeatCount="indefinite"
                      />
                  </path>
              </g>
          </svg>
          </button>
        </div>
      </form>
      
      <img src="@/assets/img/close.svg" class="close" alt="close" @click.prevent="togglePanel(!panelIsOpen)" />
    </div>
    <div class="overlay" @click.prevent="togglePanel(!panelIsOpen)"></div>
  </div>
</template>

<script>
import { createContact, uploadFileToStorage } from '@/misc/firebase-init'

export default {
  name: 'SideForm',

  props: {
    panelIsOpen: {
      type: Boolean,
      default: false,
    },
    togglePanel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      nbOfFiles: 0,
      filesToUpload: [],
      form: {},
      isSending: false,
      error: '',
    }
  },

  watch: {
    $route: {
      handler($route) {
        // We have a tracker query in URL
        if ($route && $route.query && $route.query.customSource) {
          setTimeout(() => {
            // Set it
            document.querySelector(
              '[name="customSource"]'
            ).value = `${$route.query.customSource}`
            // And register tracker
            localStorage.setItem('customSourceValue', `${$route.query.customSource}`)
            localStorage.setItem('customSourceDate', `${new Date().toISOString().slice(0, 10)}`)
          }, 1755)
        } else if($route && !$route.query.customSource) {
          // We do not have any tracker in URL
          // Do we have a registerd tracker on user ?
          // Is this tracker smaller or egual to than 14 days old ?
          const date1 = new Date(localStorage.getItem('customSourceDate'))
          const date2 = new Date()
          const diffInTime = date2.getTime() - date1.getTime()
          const diffInDays = diffInTime / (1000 * 3600 * 24)
          if (
            localStorage.getItem('customSourceValue') && 
            localStorage.getItem('customSourceDate') &&
            diffInDays <= 14
          ) {
            setTimeout(() => {
              // Set it
              document.querySelector(
                '[name="customSource"]'
              ).value = `${localStorage.getItem('customSourceValue')}`
            }, 1755)
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    getNbOfFiles(event) {
      this.nbOfFiles = document.querySelector("input[type='file']").files.length
      this.filesToUpload = event.target.files
    },
    async sendForm(event) {
      event.preventDefault()

      if (this.isSending) {
        return
      }

      this.isSending = true
      const contact = this.form

      try {
        contact.files = []
        const promises = []

        for (let i = 0; i < this.filesToUpload.length; i+=1) {
          const file = this.filesToUpload[i]
          const fileName = file.name
          contact.files[i] = fileName
          promises.push(uploadFileToStorage(file, fileName, contact.courriel))
        }

        await Promise.all(promises)
      } catch (e) {
        this.error = e
        this.isSending = false
      }
      try {
        await createContact(contact)
        this.form = {}
        window.location.href = '/success'
      } catch (e) {
        this.error = e
        this.isSending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.2s ease;

  &.showpanel {
    z-index: 20000;

    .overlay {
      opacity: 0.5;
    }

    .panel {
      right: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $colorAccent2;
  opacity: 0;
  z-index: 20000;
  transition: all 0.2s ease;
}

.panel {
  position: fixed;
  top: 0;
  right: -35vw;
  height: 100vh;
  width: 35vw;
  background-color: $colorAccent3;
  color: $dark;
  z-index: 30000;
  padding: 4vw 3vw;
  transition: all 0.2s ease;

  .close {
    position: absolute;
    top: 2vw;
    right: 1.5vw;
    width: 15px;
    cursor: pointer;
  }

  .font-black {
    font-family: $fontTitle;
    color: $dark;
    font-size: 20px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 500;
  }

  .regular-text {
    margin-bottom: 10px;
    span {
      color: $dark;
    }
  }

  form {
    input[type='text'],
    .input-ph,
    input[type='email'] {
      width: 100%;
      height: 50px;
      border: 0;
      background-color: rgba($dark, 0.1);
      padding: 10px;
      font-family: $fontText;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $dark;
      margin-bottom: 6px;
      border-radius: 3px;
      -webkit-appearance: none;

      &::placeholder {
        color: $colorAccent2;
      }
    }
    textarea {
      width: 100%;
      height: 150px;
      max-width: 100%;
      min-width: 100%;
      max-height: 150px;
      min-height: 150px;
      border: 0;
      background-color: rgba($dark, 0.1);
      padding: 10px;
      font-family: $fontText;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $dark;
      margin-bottom: 6px;
      border-radius: 3px;
      -webkit-appearance: none;

      &::placeholder {
        color: $colorAccent2;
      }
    }
  }

  @media (max-width: 1100px) {
    width: 40vw;
    right: -40vw;
  }
  @media (max-width: 900px) {
    width: 50vw;
    right: -50vw;
  }
  @media (max-width: 650px) {
    width: 65vw;
    right: -65vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    right: -100%;
    padding: 10vw 8vw;

    .close {
      top: 5vw;
      right: 5vw;
    }

    form {
      textarea {
        height: 100px;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }
}

.ctas {
  text-align: center;
  padding-top: 15px;
}

.notice {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 13px;
  margin: 0;
  line-height: 1;
}
</style>
